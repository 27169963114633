.locationHistoryContainer {
    position: relative;
    width: auto;
    height: 42px;
    margin-right: 9px;
    z-index: 999;
}

.locationHistoryContainer ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.listboxContainer {
    position: relative;
}

.NavigationMenuViewport {
    display: none;
}

.listboxButton {
    width: auto;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 9px;
    color: var(--white-text);
    padding: 0 9px;
    background-color: var(--branding-purple);
    border-radius: 9px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.buttonContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
    padding-right: 24px;
    position: relative;
}

.city {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.chevronIcon {
    position: absolute;
    right: 0;
    height: 16px;
    width: 16px;
    color: var(--white-text);
    opacity: 0.7;
}

.listboxOptions {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 3px;
    width: max-content;
    min-width: 100%;
    background-color: var(--secondary-color);
    border-radius: 9px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    max-height: 250px;
    overflow-y: auto;
    z-index: 99999999999;
    right: 0;
    animation: slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
}

.listboxOption {
    padding: 10px 12px;
    cursor: pointer;
    user-select: none;
    color: var(--text-color);
    white-space: nowrap; /* Prevent text wrapping */
    border: none;
    background-color: transparent;
}

.listboxOption:hover {
    background-color: var(--dark-thirdary);
    color: var(--white-text);
}

.optionContent {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
}

.locationTextWrap {
    display: flex;
    flex-direction: row;
    gap: 8px;

}

.active {
    background-color: var(--branding-purple) !important;
    color: var(--white-text) !important;
}

.locationText {
    font-size: 14px;
}

.selected {
    font-weight: 500;

}

.checkIcon {
    display: flex;
    align-items: center;
    color: var(--text-color);
}

.checkIconActive {
    color: var(--white-text);

}

.icon {
    height: 16px;
    width: 16px;
}

.addNewOption {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.addNewContent {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-color);
    white-space: nowrap;
}

.plusIcon {
    height: 16px;
    width: 16px;
}

.addNewText {
    font-size: 14px;
}

/* Scrollbar styling */
.listboxOptions::-webkit-scrollbar {
    width: 6px;
}

.listboxOptions::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
}

.listboxOptions::-webkit-scrollbar-thumb {
    background: var(--branding-purple);
    border-radius: 3px;
}

/* Responsive adjustments */
@media screen and (max-width: 778px) {
    .locationHistoryContainer {
        height: 38px;
    }

    .listboxButton {
        height: 38px;
    }

    .city {
        max-width: 120px;
    }
}

.locationHistoryContainer [data-state="open"] .chevronIcon {
    transform: rotate(180deg);
}

.locationHistoryContainer [role="navigation"],
.locationHistoryContainer [role="list"] {
    display: contents;
}

/* Update animation for NavigationMenu */
.listboxOptions {
    animation: slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.locationHistoryContainer [role="navigation"] {
    position: relative;
    width: 100%;
}

.locationHistoryContainer [role="list"] {
    list-style: none;
    margin: 0;
    padding: 0;
}
