.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(to bottom, var(--branding-purple), #a78bfa);
    position: relative;
    opacity: 0.84;
    z-index: 2;
}

.iconWrapper {
    animation: bounce 1s infinite;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.text {
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1rem;
}

.spinner {
    margin-top: 1rem;
    width: 4rem;
    height: 4rem;
    border: 0.25rem solid white;
    border-top: 0.25rem solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
