.dealsContainer {
    padding: 3px 0;
}

.map {
    height: 135px;
    position: relative;
    border-radius: 15px 15px 0 0;
    width: 100% !important;
}

.lottieIcon {
    max-height: 310px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lottieSvg {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
}

.lottieSvg > div {
    max-height: 80%;
    max-width: 100%;
}

.previewContainer {
    position: relative;
    z-index: 3;
    padding: 0px 0 200px;
    border-radius: 15px;
    background-color: var(--background-color);
    margin: 18px 0 0;
}

.categorySection {
    margin-bottom: 0px;
}

.categoryTitle {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 9px;
    margin-top: 0;
    padding: 0 15px;
    color: var(--text-color);
}

.productsScroller {
    display: flex;
    overflow-x: auto;
    padding-bottom: 20px;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 0 15px 20px;
}

.productsScroller::-webkit-scrollbar {
    display: none;
}

.productCardWrap {
    flex: 0 0 auto;
    width: calc(45% - 10px);
    margin-right: 10px;

}

.productCard {

    border: 1px solid #ddd;
    border-radius: 12px;
    overflow: hidden;
    background-color: white;
}

.productImage {
    width: 100%;
    height: 84px;
    object-fit: cover;
    position: relative;
    background-size: cover;
    background-position: center;
}

.productInfo {
    padding: 6px 0 0;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
}

.productMetadata {
    display: flex;
    flex-wrap: wrap;
    gap: 0.36rem;
    margin: 0.2rem 0;
}

.moreLink {
    text-decoration: underline;
    cursor: pointer;
}

.dispensaryName,
.thcContent,
.productLabels,
.cbdContent {
    font-size: 0.66rem;
    border-radius: 9999px;

    color: var(--text-color);
    font-size: .75rem;
    line-height: 1.25rem;
    background-color: rgba(75, 75, 75, 0.3);
    padding: .25rem .75rem;

}

.productName {
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-color-secondary);
}

.strainTypeTitle {

}

.dealTitle {
    background-color: var(--branding-green) !important;
    color: var(--black-text) !important;
    border: 1px solid var(--branding-green) !important;

}

.indica {
    border-color: var(--lineage-indica) !important;
    background-color: var(--lineage-indica) !important;
    color: var(--white-text) !important;
}


.sativa {
    border-color: var(--lineage-sativa) !important;
    background-color: var(--lineage-sativa) !important;
    color: var(--white-text) !important;

}


.hybrid {
    border-color: var(--lineage-hybrid) !important;
    background-color: var(--lineage-hybrid) !important;
    color: var(--white-text) !important;
}

.productPrice {
    font-weight: bold;
    margin: 0 0 10px;
}

.priceStrikethrough {
    font-weight: bold;
    text-decoration: line-through;
    margin: 0;
}

.salePrice {
    color: red;
}

.productActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 51px;
    position: absolute;
    right: 6px;
    bottom: 6px;
}

.likeButton, .dealLink {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--branding-purple);
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--icon-darker-transparent);
    border-radius: 6px;
    color: var(--text-color);
}

.likeButton:hover, .dealLink:hover {
    color: var(--branding-purple);
}

.discountWrap {
    border: 1px solid var(--light-accent);
    margin-right: 6px;
    margin-bottom: 0.25rem;
    font-size: 0.87rem;
    font-weight: bold;
    border-radius: 21px !important;
}

.discountWrap_indica {
    border-color: var(--lineage-indica) !important;
}

.discountWrap_sativa {
    border-color: var(--lineage-sativa) !important;
}

.discountWrap_hybrid {
    border-color: var(--lineage-hybrid) !important;
}


.discountLabel {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: var(--light-accent);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
}

.liked {
    color: red;
}

@media (min-width: 778px) {
    .productCardWrap {
        width: calc(25% - 10px);

    }
}

@media (min-width: 1024px) {
    .productCardWrap {
        width: calc(15% - 10px);
    }
}

/* Existing styles remain unchanged */

/* Updated bottom sheet styles */
.bottomSheetOverlay {
    position: fixed;
    top: 0;
    bottom: -1px;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 9999999;
    backdrop-filter: blur(5px);
    transition: opacity 0.3s ease;
    -webkit-transform: translateZ(0);
}

.bottomSheetOverlay.closing {
    opacity: 0;
}

/* Updated bottom sheet content styles to account for fixed footer */
.bottomSheetContent {
    background-color: var(--background-color);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    max-height: 93vh;
    position: relative;
    box-shadow: 0 -5px 20px rgba(255, 255, 255, 0.2);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    max-width: 640px;
    box-sizing: border-box;
    padding: 0 0 80px; /* Added bottom padding to prevent content from being hidden behind fixed footer */
}

.iosBottomSheetContent {
    max-height: calc(93vh - 96px);
}

.bottomSheetContent.open {
    animation: bottomSheetSlideUp 0.3s ease-out;
}

.bottomSheetContent.closing {
    transform: translateY(100%);
}

@keyframes bottomSheetSlideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.attentionLabel {
    margin: 0;
    text-align: left;
    font-weight: normal;
    text-transform: none;
    font-weight: bold;
    padding: 0.2rem 0rem;
    border-radius: 0;
    font-size: 1rem;
}

.attentionText {
    font-size: 0.75rem;
}

.bottomSheetCloseButton {
    position: absolute;
    top: 6px;
    right: 6px;
    background: var(--background-color);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    color: var(--text-color);
    opacity: 1;
    transition: opacity 0.2s ease;
    z-index: 11;
    height: 36px;
    width: 36px;
    display: flex;
    box-shadow: 0 0 3px #fff;
    justify-content: center;
    align-items: center;
}


.bottomSheetDealImage {
    width: auto;
    max-height: 120px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.bottomSheetDealInfo {
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
}

.bottomSheetDealInfo h2 {
    margin-bottom: 10px;
    color: var(--text-color);
    font-size: 18px;
}

.bottomSheetDealInfo p {
    margin-bottom: 5px;
    margin-top: 3px;
    color: var(--text-color);
    font-size: 12px;
    font-weight: 600;
    padding-right: 9px;
}

.bottomSheetFinePrint {
    font-size: 9px;
    color: var(--text-color-secondary);
    margin-top: 10px;
    opacity: 0.8;
}

.bottomSheetProductInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    border-top: 1px solid var(--border-color);
    max-width: 900px;
    margin: 9px auto 0;
}

.bottomSheetProductWrap {
    position: relative;
    background-color: var(--secondary-color);
    padding: 27px 0 9px;
    padding: 0px 0 9px;
    border-radius: 15px;
    margin: -27px 18px 9px;
    overflow: visible;
    z-index: 9;
    background: radial-gradient(circle at 39.98% -6.96%, var(--branding-purple), #91191900 71%), radial-gradient(circle at 62.02% -100.05%, var(--branding-purple), transparent 70%), radial-gradient(circle at 50% -100%, #00000000, #1a1a1a 100%)

}

.infoWrap {
    padding: 0 18px;
}

.cardTopColor {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(119, 84, 150, 1) 66%);
    height: 48px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.cardTopColor.sativa {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, var(--lineage-sativa) 66%);
}

.cardTopColor.hybrid {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, var(--lineage-hybrid) 66%);
}

.cardBackground {
    height: 100%;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: radial-gradient(circle at 39.98% -6.96%, var(--branding-purple), #91191900 71%), radial-gradient(circle at 62.02% -100.05%, var(--branding-purple), transparent 70%), radial-gradient(circle at 50% -100%, #00000000, #ffffff 87%);
}

.cardBackground.sativa {
    background: radial-gradient(circle at 39.98% -6.96%, var(--lineage-sativa), #91191900 71%), radial-gradient(circle at 62.02% -100.05%, var(--lineage-sativa), transparent 70%), radial-gradient(circle at 50% -100%, #00000000, #ffffff 87%);
}

.cardBackground.hybrid {
    background: radial-gradient(circle at 39.98% -6.96%, var(--lineage-hybrid), #91191900 71%), radial-gradient(circle at 62.02% -100.05%, var(--lineage-hybrid), transparent 70%), radial-gradient(circle at 50% -100%, #00000000, #ffffff 87%);
}

.cardBackground {
    background: #f0f0f0 !important;
}

.cardBackgroundOverlay {
    background: #f0f0f0 !important;
}

.cardBackgroundDarkOverlay {
    background: rgba(27, 27, 27, 0.9) !important;
}

.cardBackgroundDarkMode {
    background: radial-gradient(circle at 39.98% -6.96%, var(--branding-purple), #91191900 71%), radial-gradient(circle at 62.02% -100.05%, var(--branding-purple), transparent 70%), radial-gradient(circle at 50% -100%, #00000000, #1a1a1a 100%) !important;
}

.cardBackgroundDarkMode.sativa {
    background: radial-gradient(circle at 39.98% -6.96%, var(--lineage-sativa), #91191900 71%), radial-gradient(circle at 62.02% -100.05%, var(--lineage-sativa), transparent 70%), radial-gradient(circle at 50% -100%, #00000000, #1a1a1a 87%) !important;
}

.cardBackgroundDarkMode.hybrid {
    background: radial-gradient(circle at 39.98% -6.96%, var(--lineage-hybrid), #91191900 71%), radial-gradient(circle at 62.02% -100.05%, var(--lineage-hybrid), transparent 70%), radial-gradient(circle at 50% -100%, #00000000, #1a1a1a 87%) !important;
}

.productContent {
    z-index: 10;
    position: relative;
}


.pricingTiers {
    padding: 0.5rem 9px !important;
    width: calc(100% + 18px) !important;
    margin-left: -18px !important;
    margin-right: -18px !important;
}

@media screen and (min-width: 580px) {
    .pricingTiers {
        width: auto !important;
        margin-left: 0;
        justify-content: center;
    }
}

.bottomSheetProductImageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
}

.bottomSheetProductImage {
    width: auto;
    height: 120px;
    object-fit: cover;
    border-radius: 10px;

}

.bottomSheetDetailWrap {
    position: relative;
    padding: 0 9px 0;
}

.bottomSheetProductDetails {
    display: flex;

    flex: 1;
    min-width: 0; /* Allows text to wrap properly */
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 9px;
}

.bottomSheetProductDeals {
    display: flex;
    flex: 1;
    justify-content: center;
}

.bottomSheetBrandName {
    font-size: 0.9rem;
}

.bottomSheetMetaVoteRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.productInfoLeft {
    margin-top: 6px;
    margin-right: 6px;
}

.bottomSheetProductDetails h3 {
    margin-bottom: 5px;
    color: var(--text-color);
    font-size: 1rem;
    word-wrap: break-word;
}

.bottomSheetProductDetails p {
    margin-bottom: 5px;
    color: var(--text-color);
    font-size: 24px;
    word-wrap: break-word;
}

.bottomSheetProductPrice {
    font-weight: bold;
    color: var(--text-color);
    font-size: 16px;
    margin-top: 10px;
}

.bottomSheetOriginalPrice {
    text-decoration: line-through;
    color: var(--text-color-secondary);
    margin-right: 10px;
    font-size: 0.639rem
}

.bottomSheetSalePrice {
    color: var(--light-accent);
}

/* Existing styles */
.bottomSheetButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 9px;
}

.bottomSheetGetDeal, .bottomSheetViewStore, .bottomSheetHome, .bottomSheetClose {
    height: 39px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border-radius: 9px;
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    position: relative;
    max-width: 300px;
}


.bottomSheetHome, .bottomSheetClose {
    background-color: var(--thirdary-color);
    color: var(--text-color);
}

/* Updated styles for Get Deal button with pulse animation */
.bottomSheetGetDeal {

    border: 1px solid var(--branding-green);
    color: var(--black-text);
    position: relative;
    overflow: hidden;
}

.bottomSheetGetDeal::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%;
    height: 150%;
    background: radial-gradient(circle, rgb(152, 251, 152, 70%) 0%, rgb(13, 210, 41, 0%) 90%);
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: pulse 2s infinite;
    z-index: 1;
}

@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 0;
    }
}

.bottomSheetViewStore {
    background-color: var(--branding-purple);
    color: var(--white-text);
}

.comparisonItemContent {
    cursor: pointer;
}

.dispensaryTopInfo {
    padding: 0px 18px;
    border-bottom: 1px solid var(--thirdary-color);
    margin-bottom: 9px;
}

.dispensaryTopHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    margin:  9px 0;

}

.dispensaryTopLogo {
    width: 39px;
    height: 39px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px;
    cursor: pointer;
}

.dispensaryTopDetails {
    color: var(--text-color);
    font-size: 14px;
}

.dispensaryTopHeader h4 {
    font-size: 13px;
    color: var(--text-color);
}

.dispensaryTopDetails p {
    font-size: 10px;
    margin: 0;
    display: flex;
    align-items: center;
}

.dispensaryTopDetails p:last-child {
    margin-bottom: 0;
}

.dispensaryTopDetails a, .dispensaryTopDetails p a, .dispensaryTopHeader a {
    color: var(--text-color) !important;
}

.dispensaryTopDetails svg {
    margin-right: 6px;
}

/* New styles for dispensary information */
.bottomSheetDispensaryInfo {
    padding: 0 18px;
    padding-top: 9px;
    border-top: 1px solid var(--thirdary-color);
    cursor: pointer;
}

.dispensaryHeader {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.dispensaryLogo {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px;
    cursor: pointer;
}

.dispensaryHeader h3 {
    margin: 0 0 5px;
    color: var(--text-color);
    font-size: 15px;
}

.ratingWrapper {
    display: flex;
    align-items: center;
    color: var(--text-color-secondary);
    font-size: 14px;
}

.ratingWrapper span {
    color: var(--text-color);
}

.ratingWrapper svg {
    color: #ffc107;
    margin-right: 5px;
}

.dispensaryDetails {
    color: var(--text-color);
    font-size: 14px;
}

.dispensaryDetails p {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.dispensaryDetails a {
    color: var(--text-color);
}

.dispensaryDetails svg {
    margin-right: 10px;
}

/* Main content wrapper to enable scrolling */
.bottomSheetScrollContent {
    overflow-y: auto;
    max-height: calc(93vh - 80px); /* Subtract height of fixed footer */
    padding: 0px 0px 18px;
}

.bottomSheetDealContent {
    padding: 0 0 18px;
    position: relative;
}

/* Fixed footer styles */
.bottomSheetFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--background-color);
    padding: 20px;
    border-top: 1px solid var(--border-color);
    max-width: 640px;
    margin: 0 auto;
    z-index: 11;
    width: 100%;
    box-sizing: border-box;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

/* Updated button container styles */
.bottomSheetButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 9px;
    width: 100%;
}

/* Existing button styles remain the same */
.bottomSheetGetDeal, .bottomSheetViewStore {
    height: 39px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border-radius: 9px;
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
}

.bottomSheetGetDeal {
    background-color: var(--branding-green);
    text-shadow: none;
    color: var(--text-color);
    font-weight: bold;
}

.bottomSheetGetDealDark {
    background-color: transparent !important;
    color: var(--white-text);
    text-shadow: 0 0 6px #666;
    font-weight: bold;
    position: relative;
    overflow: hidden;
}



.bottomSheetViewStore {
    background-color: var(--branding-purple);
    color: var(--white-text);
}

@media screen and (max-width: 778px) {
    .dispensaryTopDetails {
        justify-content: center;
        width: 100%;
    }

    .dispensaryTopDetails p {
        font-size: 10px;
        justify-content: center;
    }

    .dispensaryTopHeader {
        margin-top: 27px;
        justify-content: center;
        flex-wrap: wrap;
    }
}
