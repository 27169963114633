.iconSquare {
    width: 42px;
    height: 42px;
    border-radius: 9px;
    background: var(--secondary-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.iconSquare svg {
    font-size: 27px;
}
