.bottomSheetOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 99999999;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.bottomSheetOverlay.visible {
    opacity: 1;
    pointer-events: auto;
}

.bottomSheet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: var(--secondary-color);
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    transform: translateY(100%);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.bottomSheet.open {
    transform: translateY(0);
    max-width: 640px;
    margin: auto;
}

.bottomSheetContent {
    padding: 24px;
    max-height: 80vh;
    overflow-y: auto;
}

.title {
    font-size: 24px;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 20px;
}

.searchInputWrapper {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--background-color);
    border-radius: 12px;
    padding: 12px 16px;
    margin-bottom: 16px;
    transition: all 0.2s ease;
    gap: 0.75rem;
}

.searchInputWrapper:focus-within {
    box-shadow: 0 0 0 2px var(--branding-purple);
}

.searchIcon {
    color: var(--text-color);
    opacity: 0.7;
    width: 20px;
    height: 20px;
}

.searchInput {
    flex: 1;
    background: transparent;
    border: none;
    padding: 0 12px;
    font-size: 16px;
    color: var(--text-color);
    outline: none;
}

.searchInput::placeholder {
    color: var(--text-color);
    opacity: 0.5;
}

.clearButton {
    background: transparent;
    border: none;
    color: var(--text-color);
    opacity: 0.7;
    cursor: pointer;
    padding: 4px;
    border-radius: 50%;
    transition: all 0.2s ease;
}

.clearButton:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
}

.predictionsContainer {
    background-color: var(--background-color);
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 20px;
}

.predictionItem {
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.predictionItem:last-child {
    border-bottom: none;
}

.predictionItem:hover {
    background-color: rgba(var(--branding-purple-rgb), 0.1);
}

.predictionContent {
    display: flex;
    align-items: center;
    gap: 12px;
}

.locationIcon {
    color: var(--branding-purple);
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.predictionText {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.mainText {
    color: var(--text-color);
    font-weight: 500;
}

.secondaryText {
    color: var(--text-color);
    opacity: 0.7;
    font-size: 14px;
}
.cancelButton {
    width: 100%;
    padding: 16px;
    background-color: var(--thirdary-color);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    color: var(--text-color);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.cancelButton:hover {
    background-color: var(--background-color);
    border-color: rgba(255, 255, 255, 0.3);
}

/* Scrollbar styling */
.bottomSheetContent::-webkit-scrollbar {
    width: 6px;
}

.bottomSheetContent::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
}

.bottomSheetContent::-webkit-scrollbar-thumb {
    background: var(--branding-purple);
    border-radius: 3px;
}

@media (max-width: 778px) {
    .bottomSheetContent {
        padding: 20px;
    }

    .title {
        font-size: 20px;
    }

    .searchInput {
        font-size: 15px;
    }
}
