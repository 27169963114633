.dealGrid {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0;
}

@media (min-width: 778px) {
    .dealGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .dealGrid {
        grid-template-columns: repeat(3, 1fr);
    }
}

.adminButton {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    font-size: 0.8rem;
    color: var(--text-color);
    border: 1px solid var(--text-color);
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
}

.dealWrap {
    width: 100%
}
.dealCardWrap {
    display: flex;
    flex-direction: row;
    gap: 9px;
}
.dealCard {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: end;
    height: 100px;
    width: 60%
}
.dealImageLeft {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dealImage {
    width: 100%;
    height: 12rem;
    object-fit: cover;
}

.dealContent {
    padding: 1rem;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.dealTitle {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.dealDescription {
    font-size: 0.875rem;
    color: #4b5563;
    margin-bottom: 0.5rem;
}

.dealFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.dealDays {
    font-size: 0.75rem;
    color: #6b7280;
}

.dealActions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: end;
    width: 100px;
}

.likeButton {
    padding: 0;
    border-radius: 9px;
    background-color: var(--icon-transparent);
    color: var(--text-color);
    width: 30px;
    height: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    border: none;
    font-size: 0.7rem;
}

.likeButtonActive {
    background-color: #fee2e2;
    color: #ef4444;
}

.redeemButton {
    background-color: var(--icon-transparent);
    color: var(--text-color);;
    padding: 0.5rem 1rem;
    border-radius: 9px;
    display: flex;
    align-items: center;
    text-decoration: none;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    justify-content: center;
    padding: 0;
    font-size: 0.8rem;
}

.redeemButton:hover {
    background-color: #059669;
}
