.container {
    background-color: var(--background-color);
    color: #ffffff;
    padding: 21px 20px 200px;
    max-width: 400px;
    margin: 0 auto;
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
}

.header svg {
    font-size: 2rem;
    color: var(--text-color);
}

.logoWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
}

.logo {
    width: 84px;
}

.backButton {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 24px;
    cursor: pointer;
}



.logo {
    color: #a020f0;
    font-weight: bold;
    font-size: 24px;
}

.nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 15px;
    margin-bottom: 20px;
}

.navButton, .activeNavButton {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 9px;
    padding: 10px;
    border: none;
    background-color: #2c2c2c;
    color: #ffffff;
    cursor: pointer;
    border-radius: 12px;
}

.activeNavButton {
    background-color: #4a90e2;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.formRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
}

.formRow .formGroup {
    width: 50%;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.formGroup label {
    font-size: 14px;
}

.formGroup select, .formGroup input, .formGroup textarea {
    background-color: #2c2c2c;
    border: none;
    padding: 10px;
    color: #ffffff;
    border-radius: 12px;
}

.imageUpload {
    background-color: #2c2c2c;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.imagePlaceholder {
    color: #8e8e93;
}

.actionButtons {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
}

.actionButtonText {
    font-size: 0.5rem;
    color: var(--dark-thirdary);
    margin-top: 3px;
}

.actionButtonTextActive {
    font-size: 0.5rem;
    color: var(--branding-purple);
    margin-top: 3px;
}

.onMenu {
    color: green;
}

.actionButton {
    background-color: #2c2c2c;
    border: none;
    padding: 10px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
}

.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}
.deleteRow {
    display: flex;
    width: 100%;
}

.saveButton, .postButton, .deleteButton {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 9px;
    padding: 15px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
}

.saveButton {
    background-color: var(--dark-black-background);
    color: #ffffff;
}

.postButton {
    background-color: #4a90e2;
    color: #ffffff;
}

.deleteButton {
    background-color: red;
    color: #ffffff;
}


   .productList {
       display: flex;
       flex-direction: column;
       gap: 10px;
   }

.productItem {
    display: flex;
    align-items: center;
    background-color: #2c2c2c;
    border-radius: 6px;
    padding: 5px;
    height: 33px;
    overflow: hidden;
}

.productThumbnail {
    width: 23px;
    height: 23px;
    object-fit: cover;
    border-radius: 3px;
    margin-right: 10px;
}

.productInfo {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.productName, .productBrand {
    font-size: 0.8rem;
    margin: 0;
}

.productBrand {
    color: #888;
}

.removeButton {
    background-color: transparent;
    color: #ff4d4d;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
}

.searchResults {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ddd;
    max-height: 200px;
    overflow-y: auto;
}

.searchResults li {
    padding: 0.5rem;
    cursor: pointer;
}

.searchResults li:hover {
    background-color: #f0f0f0;
}

.selectedImage {
    width: 100%;
    height: 150px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    color: var(--black-text);
    font-size: 45px;
}

.selectedImage img {
    max-height: 100%;
}
