.pillContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
}

.pill {
    background-color: var(--background-color);
    color: var(--text-color);
    font-size: 0.54rem;
    font-weight: 500;
    padding: 0.2rem 0.42rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    border: var(--branding-purple) 1px solid;
    margin: 0 0 0 0;
    width: 42%;
}

.discountLabel {
    margin-right: 0.25rem;
}

.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 105px;
}
